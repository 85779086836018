import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import { FaCheck } from 'react-icons/fa';
import PhotoGallery from '../components/Gallery';

const Dziwnowek3 = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/amber3/2-main.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
    }
    mobileImage: file(relativePath: { eq: "images/photos/amber3/2-mobile.jpg" }) {
      childImageSharp {
          fluid(quality: 50){
          ...GatsbyImageSharpFluid
          }
      }   
    }
  }`);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];


  return (
    <Layout location={props.location}>
      <Fade up>
        <section className="area">
          <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
          <h1 className="title">APARTAMENTY DZIWNÓW</h1>
          <div className="content-container">

            <h1>Apartament Amber 3</h1>
            <p>Apartament Amber 3 w Dziwnowie o powierzchni 61 metrów kwadratowych mieści się na 2 piętrze w budynku z windą w kompleksie, w którym znajduje się dostępny dla mieszkanców basen z saunami. Apartament nowocześnie i komfortowo urządzony w wysokim standardzie .</p>
            <p>Posiada salon z rozkładanym narożnikiem, kominkiem i balkonem, w pełni wyposażony aneks kuchenny, 2 osobne, zamykane sypialnie z szafami, TV i balkonami, jedna dysponuje łożem małżeńskim, druga rozkładaną, wygodną sofą, łazienkę z prysznicem, przedpokój. Przynależy do niego miejsce postojowe MP15 w hali garażowej, do której zjeżdża winda.</p>
            <p>Widok z balkonów od strony sypialni rozpościera się na przepływającą rzekę Dziwną i Marinę z zacumowanymi żaglówkami.</p>
            <p>Na zamkniętym osiedlu znajduje się także mały plac zabaw.</p>
            <p>Apartament przystosowany jest do pomieszczenia 6 osób, dodatkowo wyposażony w łóżeczko turystyczne (bez pościeli) i krzesełko do karmienia dla dzieci.</p>
            <p>Do dyspozycji strefa SPA (basen, jacuzzi, sauny) oraz sala fitness w cenie pobytu - bez limitu wejść!</p>
            <h1>Wyposażenie:</h1>
            <ul>
              <li><FaCheck />klimatyzacja</li>
              <li><FaCheck />smart TV 55 cali - salon</li>
              <li><FaCheck />2 x smart TV 32 cale – sypialnie</li>
              <li><FaCheck />wi - fi </li>
              <li><FaCheck />kominek </li>
              <li><FaCheck />płyta indukcyjna - 4 palniki</li>
              <li><FaCheck />zmywarka</li>
              <li><FaCheck />ekspres do kawy</li>
              <li><FaCheck />czajnik elektryczny</li>
              <li><FaCheck />toster</li>
              <li><FaCheck />lodówka z zamrażalnikiem</li>
              <li><FaCheck />kuchenka mikrofalowa</li>
              <li><FaCheck />zestaw naczyń do swobodnego przygotowania i spożycia każdego posiłku</li>
              <li><FaCheck />stół jadalniany dla 6 osób</li>
              <li><FaCheck />stolik kawowy</li>
              <li><FaCheck />kabina prysznicowa, umywalka, wc</li>
              <li><FaCheck />pralka oraz suszarka na prania</li>
              <li><FaCheck />chemia  na start w tym (papier toaletowy, mydło, kostki do zmywarki, płyn do naczyń itp.)</li>
              <li><FaCheck />suszarka do włosów</li>
              <li><FaCheck />kpl ręczników dla każdego Gościa</li>
              <li><FaCheck />odkurzacz</li>
              <li><FaCheck />w pełni wymiarowy narożnik  z możliwością rozłożenia – salon</li>
              <li><FaCheck />łoże małżeńskie - sypialnia</li>
              <li><FaCheck />wygodna, rozkładana sofa - sypialnia</li>
              <li><FaCheck />zestaw zabawek dla dzieci</li>
              <li><FaCheck />na balkonach meble ogrodowe</li>
              <li><FaCheck />żelazko i deska do prasowania</li>
              <li><FaCheck />parawan</li>
            </ul>

            <p style={{ marginTop: 20 }}>W apartamencie Amber 3 <b>NIE akceptujemy</b> zwierząt.</p>

          </div>

        </section>
      </Fade>


      <Fade up>
        <section className="photos" id="section3">
          <h1>Galeria</h1>
          <div className="photos">
            <PhotoGallery galleryType='apartament3' />
          </div>
        </section>
      </Fade>

    </Layout>
  )
}



export default Dziwnowek3

